<template>
  <div>
    <weightedButtons
      :selected="selected"
      :items="testStatus"
      :totalItems="total"
      :enableTotal="true"
      @selectedItem="selectedItem"
      :key="currentTable.id"
      :statusBarBig="
        typeof currentTable.statusBarBig != 'undefined'
          ? currentTable.statusBarBig
          : true
      "
    ></weightedButtons>
    <standbyDialog :localLoadingDialog="loading"></standbyDialog>
  </div>
</template>
<script>
import standbyDialog from "@/commonComponents/standbyDialog.vue";
import weightedButtons from "@/commonComponents/weightedButtons.vue";
import requests from '@/js/requests';

export default {
  props: ["currentTable"],
  components: { weightedButtons, standbyDialog },
  data() {
    return { /* testStatus: {},*/ selected: [], loading: false  };
  },
  created() {
    if (this.currentTable.status) {
      this.selected = this.currentTable.status.split(",");
      //this.selectedItem(this.selected);
    }
    //  this.$nextTick(function () {
    // this.getStatusObject();
    //  });
    // this.unsubscribe = this.$store.subscribe((mutation) => {
    //   if (mutation.type === "tableChanged") {
    //     // this.getStatusObject();
    //     this.$nextTick(function () {
    //       this.selectedItem(this.selected);
    //     });
    //   }
    // });
  },
  // beforeDestroy() {
  //   this.unsubscribe();
  // },
  watch: {
    "currentTable.status": function (val) {
      if (val) {
        this.selected = val.split(",");
      } else {
        this.selected = [];
      }
    },
    // "currentTable.currentItems": function () {
    //   this.getStatusObject();
    //   // this.selectedItem(this.selected)
    // },
  },
  computed: {
    total() {
      let total = 0;
      if (typeof this.currentTable.stats != "undefined") {
        total = this.currentTable.stats.n_testsTotal;
      }
      return total;
    },
    testStatus() {
      // let itemsLength = this.currentTable.dataCount;
      let testStatus = [
        { name:  this.language.testStatusLabel.pending,
          color: "pending",
          index: "0"
        },
        {
          name: this.language.testStatusLabel.running,
          color: "testStatusRunning",
          type: "n_testRunning",
          index: "1",
        },
        {
          name: this.language.testStatusLabel.ok,
          color: "ok",
          type: "n_testsOK",
          index: "2"
        },
        {
          name: this.language.testStatusLabel.aborted,
          color: "testStatusAborted",
          type: "n_testsAborted",
          index: "5",
        },
        {
          name: this.language.testStatusLabel.maxruntime,
          color: "testStatusMaxRuntime",
          type: "n_testsMaxRuntime",
          index: "6",
        },
        {
          name: this.language.testStatusLabel.processingWarning,
          color: "testStatusRuntimeWarning",
          type: "n_testsRuntimeWarning",
          index: "7",
        },
        {
          name: this.language.testStatusLabel.processingError,
          color: "testStatusRuntimeError",
          type: "n_testsRuntimeError",
          index: "8",
        },
        {
          name: this.language.testStatusLabel.resultWarning,
          color: "testStatusWarning",
          type: "n_testsWarning",
          index: "3",
        },
        {
          name: this.language.testStatusLabel.resultError,
          color: "testStatusError",
          type: "n_testsError",
          index: "4",
        },

      ];

      let counted = 0;
      let stats = this.currentTable.stats;
      if (typeof this.currentTable.stats != "undefined") {
        testStatus.forEach((info) => {
       
          if (typeof info["type"] != "undefined") {
            info["count"] = typeof stats[info["type"]]==="undefined"?'0':stats[info["type"]];
            counted += parseInt(stats[info["type"]]);
          }
        });

        if (typeof stats["n_testsTotal"] != "undefined") {
          let statusCount = stats["n_testsTotal"] - counted;
          testStatus[0]["count"] = typeof statusCount==="undefined" || isNaN(statusCount)? '0':statusCount;
        }
      }
      if (this.currentTable.disableRunning) {
       // delete testStatus[0];
       // delete testStatus[1];
         testStatus.splice(0,2);
      }
      // }
      // this.testStatus = testStatus;
      //console.log(testStatus);
      return testStatus;
    },
  },
  methods: {
    filterStatus(source, status) {
      var tStatus = status;
      return source.filter(function (el) {
        return el.n_status == tStatus;
      });
    },
    filterStatusMulti(source, selected) {
      return source.filter(function (el) {
        return selected.includes(el.n_status);
      });
    },
    selectedItem(selected) {
      this.selected = selected;
      this.loading = true;
      let caller = this;
      let regex = /(status=).*?(&|$)/;
      let newUrl;
      let shortURL;
      if (this.currentTable.tableUrl.match(regex) != null) {
        newUrl = this.currentTable.tableUrl.replace(
          regex,
          "$1" + selected.join(",") + "$2"
        );

        shortURL = this.currentTable.tableShortUrl.replace(
          regex,
          "$1" + selected.join(",") + "$2"
        );
      } else {
        newUrl = this.currentTable.tableUrl + "&status=" + selected.join(",");
        shortURL =
          this.currentTable.tableShortUrl + "&status=" + selected.join(",");
      }
      history.pushState({}, null, shortURL);
      requests.frameworkAxiosRequest({
        method: 'post',
        url: newUrl,
        //data: { status: selected.join(",") },
      })
          .then(function (response) {
          caller.$store.commit("tableChanged", response.data.result.table);
          caller.loading = false;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
          caller.loading = false;
        });
    },
  },
};
</script>
